import React from "react";
import { MegaphoneIcon, XMarkIcon } from "@heroicons/react/24/outline";

const Banner = ({ handleBannerClose, isBannerOpen }) => {
  const year = new Date().getFullYear();

  return (
    <>
      {/*
        Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
        your content from being obscured when the user scrolls to the bottom of the page.
      */}
      <div
        className={` z-10 fixed inset-x-0 bottom-0 transition-all ease-in duration-200 ${
          isBannerOpen ? "block" : "hidden"
        }`}
      >
        <div className="bg-indigo-700">
          <div className="mx-auto py-3 px-6">
            <div className="flex flex-wrap items-center justify-between">
              <div className="flex w-0 flex-1 items-center">
                <span className="flex rounded-lg bg-indigo-800 p-2">
                  <MegaphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
                <p className="ml-3 truncate font-medium text-white">
                  <span className="md:hidden">
                    Wir haben die Verlängerung der THG-Quoten für {year}
                    angekündigt.
                  </span>
                  <span className="hidden md:inline">
                    Sie haben nun die Möglichkeit, die THG-Quote Ihrer aktuellen
                    Fahrzeuge auch für {year} zu beantragen. Klicken Sie dafür
                    bitte auf den nachfolgenden Button, um zur
                    Verlängerungsseite zu gelangen.
                  </span>
                </p>
              </div>
              <div className="order-3 mt-2 w-full flex-shrink-0 sm:order-2 sm:mt-0 sm:w-auto">
                <a
                  href={`/authenticated/renew`}
                  className="flex items-center justify-center rounded-md border border-transparent bg-white px-4 py-2 text-sm font-medium text-indigo-600 shadow-sm hover:bg-indigo-50"
                >
                  Jetzt verlängern!
                </a>
              </div>
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-3">
                <button
                  type="button"
                  onClick={handleBannerClose}
                  className="-mr-1 flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white sm:-mr-2"
                >
                  <span className="sr-only">Dismiss</span>
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Banner;
