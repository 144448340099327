import React, { useState, useEffect } from "react";
import { Typography, Paper, Collapse, Grid, Button } from "@material-ui/core";
import MyFileUpload from "../../fields/MyFileUpload";
import { StaticImage } from "gatsby-plugin-image";
import { Formik, Form } from "formik";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Box } from "@material-ui/core";
import { addQuote } from "../../../api";
import { navigate } from "gatsby";
import addVehicleScheme from "../../../helper/schemes/addVehicleScheme";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { makeStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles";
import Loading from "../../Loading";
import MyCheckboxGroup from "../../fields/MyCheckboxGroup";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2rem",
  },
  btn: {
    color: theme.palette.primary.light,
    padding: "0.5rem",
    fontWeight: "bold",
    background: theme.palette.primary.main,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  icon: {
    fontSize: "2rem",
    marginRight: "1rem",
  },
  paper: {
    textAlign: "start",
    marginTop: "2rem",
  },
  collapse: {
    transition: "1s",
  },
  uploads: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    display: "none",
  },
  paperImage: {
    width: "100%",
    marginBottom: "1rem",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  gridBtn: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
  },
  saveBtn: {
    background: theme.palette.primary.main,
    color: theme.palette.primary.light,
    "&:hover": {
      background: lighten(theme.palette.primary.main, 0.2),
    },
  },
  text: {
    marginTop: "2rem",
  },
  image1: {
    borderRadius: "5px",
    boxShadow: "0.5px 0.5px 5px #000",
  },
  image2: {
    borderRadius: "5px",
    marginTop: "1rem",
    boxShadow: "0.5px 0.5px 5px #000",
  },
  upload: {
    paddingRight: "1rem",
  },
  uploadBox: {
    width: "80%",
  },
  divider: {
    width: "80%",
  },
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120,
  },
}));

const types = [];

const Vehicle = ({ prices, setMessage }) => {
  const [open, setOpen] = useState(false);
  const [typeOptions, setTypeOptions] = useState();

  const handleClick = () => {
    setOpen(!open);
  };

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView();
  }, [trackPageView]);

  const submitData = (data, formikProps) => {
    setLoading(true);
    const unauthorizedCallback = () => {
      if (typeof window !== `undefined`) {
        navigate(`/login`);
      }
    };
    console.log("add quote");

    addQuote(data, unauthorizedCallback).then((success) => {
      if (success) {
        formikProps.resetForm();
        setOpen(false);
      }
      setMessage();
      setLoading(false);
    });
  };

  const quoteType = (type) => {
    switch (type) {
      case "m1":
        return "M1";
      case "other":
        return "M1 Baumpflanzaktion";
      case "n1":
        return "N1";
      case "n2":
        return "N2";
      case "n3":
        return "N3";
    }
  };

  useEffect(() => {
    getTypes();
    setTypeOptions(getTypes());
  });

  const getTypes = () => {
    if (prices.length) {
      (prices || []).map((price, index) => {
        let type;
        switch (price.type) {
          case "other":
            type = "M1 Baumpflanzaktion";
            break;
          case "m1":
            type = "M1";
            break;
          case "n1":
            type = "N1";
            break;
          case "n2":
            type = "N2";
            break;
          case "n3":
            type = "N3";
            break;
        }
        types[index] = {
          label: `${type}`,
          value: price.type,
        };
      });
      return types;
    }
  };

  if (loading) return <Loading />;

  const year = new Date().getFullYear();

  return (
    <Formik
      initialValues={{
        frontUpload: "",
        isOther: [],
      }}
      validationSchema={addVehicleScheme}
      onSubmit={(values, formikProps) => {
        // same shape as initial values
        submitData(
          {
            ...values,
            isOther: values.isOther.includes("yes") ? 1 : 0,
          },
          formikProps
        );
      }}
    >
      {(props) => (
        <Form className={classes.login}>
          <div className={classes.root}>
            <Grid container>
              <Grid item xs={12} align="start">
                <Button
                  variant="contained"
                  size="small"
                  onClick={handleClick}
                  className={classes.btn}
                >
                  <AddCircleIcon className={classes.icon} />
                  <Typography variant="body1">Fahrzeug hinzufügen</Typography>
                </Button>
              </Grid>
            </Grid>
            <Collapse
              in={open}
              timeout="auto"
              unmountOnExit
              className={classes.collapse}
            >
              <Paper elevation={0} className={classes.paper}>
                <Grid container className={classes.container}>
                  <Grid item xs={12} md={8} lg={10}>
                    <div className="flex flex-col sm:flex-row  sm:items-center mb-4 sm:my-0">
                      <p className="mr-4 mb-1 text-[#070606] text-[1.3rem] font-[400] leading-[1.5rem]">
                        Sie haben verschiedene Optionen zur Vermarktung Ihrer
                        THG-Quote im Jahr {year}:
                      </p>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 mb-4 gap-2">
                      {!!prices.length &&
                        prices.map((p, index) => {
                          return (
                            <div
                              key={index}
                              className="border border-lightestGreen text-center py-2 rounded-[0.75rem]"
                            >
                              <p className="text-lightestGreen text-[1rem] font-[700] leading-[1.25rem]">
                                {quoteType(p.type)}
                              </p>
                              <h1 className="text-lightGreen font-[400] text-[1.5rem] sm:text-[3.45rem] leading-[4.2rem]">
                                {p.price} €
                              </h1>
                              <p className="text-lightestGreen text-[1rem] leading-[1.25rem] font-[300]">
                                THG-Prämie {year}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                    <Grid
                      style={{
                        marginTop: 64,
                      }}
                      item
                      xs={12}
                    >
                      <MyCheckboxGroup
                        label="M1 Baumpflanzaktion"
                        options={[
                          {
                            label: (
                              <Box style={{ textAlign: "left" }}>
                                <Typography variant="body1">
                                  Teilnahme an der Baumpflanzaktion (erfordert
                                  ein M1-Fahrzeug)
                                </Typography>
                              </Box>
                            ),
                            value: "yes",
                          },
                        ]}
                        name="isOther"
                      />
                    </Grid>
                    <Typography
                      component="h1"
                      variant="h6"
                      className={classes.text}
                    >
                      Zulassungsbescheinigung Teil 1
                    </Typography>
                    <Typography
                      component="h2"
                      variant="body1"
                      color="secondary"
                    >
                      (Vorderseite)
                    </Typography>
                    <div className={classes.upload}>
                      <Typography variant="body2">
                        Wählen Sie per Klick eine Datei auf das Feld oder legen
                        Sie sie hier ab (JPG, PNG, PDF)
                      </Typography>
                      <Box my={4} className={classes.uploadBox}>
                        <MyFileUpload
                          label="Vorderseite"
                          id="frontUpload"
                          name="frontUpload"
                        />
                        <StaticImage
                          src="../../../media/images/Zulassungsbescheinigung1.jpg"
                          className={classes.image1}
                          alt="Zulassungsbescheinigung1"
                        />
                      </Box>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={5}
                    className={classes.zulassung}
                  ></Grid>
                  <Grid item xs={12} className={classes.gridBtn}>
                    <Button
                      type="submit"
                      variant="contained"
                      className={classes.saveBtn}
                      disabled={props.isSubmitting}
                    >
                      Speichern
                    </Button>
                  </Grid>
                  <Grid item xs={4}></Grid>
                </Grid>
              </Paper>
            </Collapse>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default Vehicle;
