import React, { useEffect, useState } from "react";

import store from "../../../redux/store";
import { Typography, Container } from "@material-ui/core";

import AuthenticatedLayout from "../layout/Layout";
import AddVehicle from "./AddVehicle";
import { useSelector } from "react-redux";
import Message from "../Message";
import { navigate } from "gatsby";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import Dialog from "../dialog";
import { BASE_URL } from "../../../api/config";
import { Banner, DialogBox } from "../../Renewal";
import { getPrices } from "../../../api";
import useStyles from "../../../styles/main";
import Link from "../../Link";
const Default = () => {
  const classes = useStyles();
  const quotes = useSelector((state) => state.quotes.items);
  const prices = useSelector((state) => state.prices.items);
  const { trackPageView } = useMatomo();
  const [open, setOPen] = useState(false);
  const [isBannerOpen, setIsBannerOpen] = useState(false);
  const handleClose = () => {
    setOPen(false);
    setIsBannerOpen(true);
  };
  const handleBannerClose = () => {
    setIsBannerOpen(false);
  };
  React.useEffect(() => {
    trackPageView();
  }, []);

  useEffect(() => {
    const unauthorizedCallback = () => {
      if (typeof window !== `undefined`) {
        navigate(`/login`);
      }
    };
  }, []);

  const [showMessage, setShowMessage] = React.useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowMessage(false);
    }, 15000);
  }, [showMessage]);

  const setMessage = () => {
    setShowMessage(true);
  };

  useEffect(async () => {
    const token = store.getState()?.user?.token;
    const response = await fetch(`${BASE_URL}/thg/quote-years`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    const json = await response?.json();
    if (json.data) {
      setOPen(true);
    }
  }, []);

  useEffect(() => {
    getPrices();
  }, []);

  return (
    <AuthenticatedLayout>
      <div className={classes.root}>
        <Container maxWidth="md">
          <Typography
            variant="h2"
            gutterBottom
            className="text-[40px] font-[400] sm:leading-[72px] text-darkGreen md:text-[60px]"
          >
            Ihre Fahrzeuge{" "}
          </Typography>
          {!store.getState().user?.subscription?.unsubscribed_at ? (
            <Typography>
              Sie haben es fast geschafft - Laden Sie abschließend die
              Vorderseite der Zulassungsbescheinigung Teil I (Fahrzeugschein)
              hoch. Danach kümmern wir uns für Sie um den Rest. Haben Sie
              Fragen, erreichen Sie uns{" "}
              <Link href="/contact" className="underline">
                hier
              </Link>
              .
            </Typography>
          ) : (
            <Box my={2} align="start" style={{ width: "100%" }}>
              <Alert severity="warning">
                <AlertTitle variant="h6">Kündigung vorgemerkt!</AlertTitle>
                <Typography variant="body1" style={{ whiteSpace: "pre-wrap" }}>
                  "Sie haben unseren THG-Quotenservice gekündigt. Daher können
                  Sie keine weiteren Fahrzeuge hinzufügen. Sollten Sie unseren
                  Service jedoch wieder nutzen wollen, müssen Sie Ihr Konto im
                  Bereich 'Persönliche Einstellungen' reaktivieren."
                </Typography>
              </Alert>
            </Box>
          )}
          <Typography className={classes.note}>
            Bitte fügen Sie zum Vervollständigen Ihres Profils Ihre Kontodaten
            im Bereich "Persönliche Einstellungen hinzu.
          </Typography>
          <Typography className={classes.note}>
            <b>Hinweis:</b> Mit der “Pflanz einen Baum”-Aktion haben Sie die
            Möglichkeit noch mehr fürs Klima zu tun und etwas zur Aufforstung
            der Wälder beizutragen. Sie tun was gutes für die Umwelt und
            erhalten zusätzlich eine attraktive Auszahlung.
          </Typography>
          <Dialog />
          {!store.getState().user?.subscription?.unsubscribed_at
            ? quotes.length < 100 && (
                <AddVehicle prices={prices} setMessage={setMessage} />
              )
            : null}

          {showMessage ? <Message /> : null}
        </Container>

        {!store.getState().user?.subscription?.unsubscribed_at ? (
          <>
            <DialogBox handleClose={handleClose} isOpen={open} />
            <Banner
              handleBannerClose={handleBannerClose}
              isBannerOpen={isBannerOpen}
            />
          </>
        ) : null}
      </div>
    </AuthenticatedLayout>
  );
};

export default Default;
